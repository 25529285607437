import Menu from '../../components/Menu';
import Browser from '../../components/Browser';

const Landing = () => (
  <>
    <Menu/>
    <Browser>
    <ol className="list-decimal">
      <li>
        Select the remote desktop program from the dropdown and click on the
        &nbsp;<strong>Start local collection</strong> button.
        <br/>
        You can provide any additional info about the data collection in the
        &nbsp;<strong>Additional info</strong> field.
        <br/>
        In case of any error you can abort the data collection by clicking on
        the <strong>Restart the process</strong> button.
      </li>
      <li>
        Move your mouse until you collect the required amount of mouse events.
      </li>
      <li>
        Click on the <strong>Stop local collection</strong> button.
      </li>
      <li>
        Hand over the control of your mouse to the remote party.
      </li>
      <li>
        When you - as the remote party - receive the control you should click on
        the <strong>Start remote collection</strong> button.
      </li>
      <li>
        Move your mouse until you collect the required amount of mouse events.
      </li>
      <li>
        Click on the <strong>Stop remote collection</strong> button.
      </li>
      <li>
        Hand back the control of the mouse to the local party.
      </li>
      <li>
        When you - as the local party - receive back the control you can start
        a new collection with the <strong>Restart the process</strong>
        &nbsp;button.
      </li>
    </ol>
    </Browser>
  </>
);

export default Landing;
